$spaces: (0, 1, 2, 3, 4, 5, 7, 10, 15, 20, 25, 30, 40, 50, 60, 75, 80, 100) !default;
$formats: (
                ("p", ("padding"), ""),
                ("p-i", ("padding"), "!important"),
                ("pv", ("padding-top", "padding-bottom"), ""),
                ("pv-i", ("padding-top", "padding-bottom"), "!important"),
                ("ph", ("padding-left", "padding-right"), ""),
                ("ph-i", ("padding-left", "padding-right"), "!important"),
                ("pt", ("padding-top"), ""),
                ("pt-i", ("padding-top"), "!important"),
                ("pb", ("padding-bottom"), ""),
                ("pb-i", ("padding-bottom"), "!important"),
                ("pl", ("padding-left"), ""),
                ("pl-i", ("padding-left"), "!important"),
                ("pr", ("padding-right"), ""),
                ("pr-i", ("padding-right"), "!important"),
                ("m", ("margin"), ""),
                ("m-i", ("margin"), "!important"),
                ("mv", ("margin-top", "margin-bottom"), ""),
                ("mv-i", ("margin-top", "margin-bottom"), "!important"),
                ("mh", ("margin-left", "margin-right"), ""),
                ("mh-i", ("margin-left", "margin-right"), "!important"),
                ("mt", ("margin-top"), ""),
                ("mt-i", ("margin-top"), "!important"),
                ("mb", ("margin-bottom"), ""),
                ("mb-i", ("margin-bottom"), "!important"),
                ("ml", ("margin-left"), ""),
                ("ml-i", ("margin-left"), "!important"),
                ("mr", ("margin-right"), ""),
                ("mr-i", ("margin-right"), "!important"),
);

@each $name, $rows, $attr in $formats {
  @each $space in $spaces {
    @if $space == 0 {
      .no-#{$name} {
        @each $row in $rows {
          #{$row}: #{$space} #{$attr};
        }
      }
    } @else {
      .#{$name}-#{$space} {
        @each $row in $rows {
          #{$row}: #{$space}px #{$attr};
        }
      }
    }
  }
}
