@import "../../../../../node_modules/bootstrap/scss/functions";
@import "../../../../../assets/scss/poloVariables";
@import "../../../../../node_modules/bootstrap/scss/variables";
$background-primary-color: $white;
$background-secondary-color: $gray-100;
$background-contrast-color: $black;
$title-color: $gray-900;
$text-color: $gray-800;

$breadcrumb-color: $gray-500;

$tag-border-color: $gray-200;
$tag-text-color: $text-color;

$footer-background-color: $gray-300;
$footer-text-color: $text-color;

$cardBgColor: $background-primary-color;
$cardBorderColor: $gray-200;
$cardDateBgColor: $gray-200;
$cardDateColor: $black;
$cardInfoColor: $gray-800;
$eventPreRoleColor: $gray-700;
$eventRoleColor: $gray-800;
$eventNameColor: $text-color;
$eventTicketPriceColor: $gray-900;
$eventTicketButtonTextColor: $background-primary-color;
$amazonListActive: $background-primary-color;
$peopleShadow: $background-primary-color;
$galleryCardTextColor: $text-color;
$titleColor: $text-color;

$cardBgColor: $white;
$cardBorderColor: $gray-200;
$cardDateBgColor: $gray-200;
$cardDateColor: $black;
$cardInfoColor: $gray-800;
$eventPreRoleColor: $gray-700;
$eventRoleColor: $gray-800;
$eventNameColor: $gray-900;
$eventTicketPriceColor: $gray-900;
$eventTicketButtonTextColor: $white;
$amazonListActive: $white;
$peopleShadow: $white;
$galleryCardTextColor: $black;
$titleColor: #090909;

@import "frontendMain";
