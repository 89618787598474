$titleColor: #0e0e0e;

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  line-height: 1.05;
  color: $titleColor;
}

.page-text {
  h1 {
    font-size: 2em;
  }
}
