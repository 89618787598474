form {
  .help-block.help-block-error {
    color: red;
    margin-top: 5px;
  }
}

img.qr-code {
  width: unset !important;
  min-width: unset !important;
  max-width: 100% !important;
  height: auto !important;
  border-radius: 0 !important;
}

.tickets {
  .ticket {
    padding-bottom: 5px;
    border-bottom: 1px solid #dadada;
    margin-bottom: 5px;

    &:last-child {
      border-bottom: none;
    }
  }
}

.testimonial {
  .testimonial-item {
    padding: 25px !important;

    > {
      img {
        height: 128px;
        width: 128px;
      }
    }
  }
}
