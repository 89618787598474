#page-title {
  &.quote {
    background: #fff;
    background-repeat: no-repeat;
    background-size: 66vw;
    background-position-x: 33.5vw;
    min-height: (100vw * 9 / 16);
    max-width: 1440px;
    margin: 0 auto;

    @media (min-width: 1440px) {
      background-size: (1440px * 66 / 100);
      background-position-x: (1440px * 33.5 / 100);
      min-height: (1440px * 9 / 16);
    }

    .container {
      .page-title {
        position: absolute;
        top: 10vw;
        left: 12vw;

        h1 {
          font-family: "Playfair Display", Times, serif;
          font-size: 5.5em;
          margin-bottom: 100px;
          letter-spacing: -5px;
          line-height: 0.8;
          @media (max-width: 768px) {
            margin-bottom: calc((100vw * 9 / 16) - 0.5em);
          }
        }

        p {
          width: 30%;
          font-style: italic;
          @media (max-width: 991px) {
            width: 50%;
          }
          @media (max-width: 768px) {
            width: 90%;
          }
        }

        @media (min-width: 1440px) {
          top: (144px);
        }
      }
    }

    @media (max-width: 768px) {
      background-position-x: 100%;
      background-size: 100%;
      min-height: calc((100vw * 9 / 16) + 250px);
    }
  }
}
