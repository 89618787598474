#news {
  margin-top: 50px;
  margin-bottom: 50px;
  font-size: 0.853em;

  .card {
    .card-body {

      p {
        font-size: 1.25em;
      }

      @media (max-width: 767px) {
        height: 220px;
      }
      @media (max-width: 520px) {
        height: auto;
      }
    }
  }
}

.card-news {
  margin-top: 50px;
  margin-bottom: 50px;

  .news-title {
    text-align: center;
    margin-bottom: 40px;
    font-weight: 400;
    font-size: 2.06em;
  }
}
