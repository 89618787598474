$tag-border-color: $gray-200 !default;
$background-primary-color: $white !default;
$text-color: $gray-800 !default;
$background-secondary-color: $gray-100 !default;

.accordion {
  .ac-item {
    margin-bottom: 10px;
    padding: 12px 20px;
    border-radius: 5px;
    border: 1px solid $tag-border-color;
    background-color: $background-primary-color;
    color: $text-color;

    .ac-title {
      font-weight: 500;
      position: relative;
      padding-right: 10px;
      margin: 0;
      font-size: 1em;
      cursor: pointer;
      user-select: none;

      &:before {
        font-size: 18px;
        content: "\f054";
        font-family: 'Font Awesome 6 Free';
        font-weight: 900;
        right: 0;
        position: absolute;
      }

      > i {
        margin-right: 8px;
      }
    }

    .ac-content {
      padding-top: 12px;

      > p:last-child {
        margin-bottom: 6px;
      }
    }

    &.ac-active .ac-title:before {
      content: "\f078";
    }
  }

  &.accordion-shadow .ac-item {
    box-shadow: 0 6px 38px rgba(0, 0, 0, 0.05);
  }

  &.accordion-simple .ac-item {
    padding: 14px 0;
    margin-bottom: 0;
    border-radius: 0;
    border: 0;
    border-bottom: 1px solid $tag-border-color;

    &:last-child {
      border: 0;
    }
  }


  &.accordion-flat .ac-item {
    padding: 10px 0;
    margin-bottom: 0;
    border-radius: 0;
    border: 0;
  }

  &.accordion-theme .ac-item {
    background-color: $primary;
    border: 0;

    .ac-title {
      color: $background-secondary-color;
    }

    .ac-content,
    .ac-content p {
      color: $background-secondary-color;
    }
  }
}