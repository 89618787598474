.special-title {
  font-family: "Playfair Display", Times, serif;
  font-size: 4em;
  letter-spacing: -7px;
}

.image-text {
  .row {
    > div[style] {
      transition: transform .4s ease-in-out;

    }

    &:hover {
      > div[style] {
        transform: scale(1.05);
      }
    }
  }
}

.logos {
  .row {
    > div[style] {
      overflow: hidden;
      background-size: cover !important;
      background-position: center center;
      background-repeat: no-repeat;
      transition: transform .4s ease-in-out;

      &:hover {
        transform: scale(1.05);
      }
    }
  }
}