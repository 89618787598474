$peopleShadow: $white;

.people {
  .grid {
    margin-left: 0;

    li {
      .people {
        text-align: center;
        margin-top: -10px;

        h5 {
          text-transform: uppercase;
          margin: 0;
          font-size: 1.25em;
          letter-spacing: -1px;
          line-height: 1;
          text-shadow: -1px -1px 0 $peopleShadow, 1px -1px 0 $peopleShadow;
        }

        h6 {
          margin: 0;
          font-size: 1em;
          letter-spacing: -1px;
          line-height: 1;
        }
      }
    }
  }
}

.people-inline {
  font-size: 0.9rem;
  display: inline-block;
  opacity: 0.75;
}

body {
  &.breakpoint-md .carousel-description-clients .flickity-button,
  &.breakpoint-xs .carousel-description-clients .flickity-button {
    display: none;
  }

  &.breakpoint-md .people .grid li,
  &.breakpoint-sm .people .grid li {
    width: 50%;
  }

  &.breakpoint-xs .people .grid li {
    width: 100%;
  }
}