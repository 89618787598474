$cardBgColor: $white !default;
$cardBorderColor: $gray-200 !default;
$cardPromoHeight: 522px !default;
$cardDateFontFamily: 'Roboto', sans-serif !default;
$cardDateBgColor: $gray-200 !default;
$cardDateColor: $black !default;
$cardTitleFontFamily: 'Poppins', sans-serif !default;
$cardTitleColor: $primary !default;
$cardInfoFontFamily: 'Roboto', sans-serif !default;
$cardInfoColor: $gray-800 !default;

.ball {
  width: 40px;
  height: 40px;
  text-align: center;
  background: red;
  border-radius: 20px;
  color: white;
  margin-right: 5px;
  position: absolute;
  left: 10px;
  top: 10px;
  font-size: 1.5em;
  padding: 8px;
  font-weight: bold;

  &.big {
    width: 100px;
    font-size: 1.25em;

  }
}

.pill {
  width: 80px;
  height: 40px;
  text-align: center;
  background: #0587af;
  border-radius: 20px;
  color: white;
  margin-right: 5px;
  position: absolute;
  right: 10px;
  top: 10px;
  font-size: 1.5em;
  padding: 8px;
  font-weight: bold;
}

.events {
  &.boxes {
    .equalize {
      .cover {
        overflow: hidden;
        background-position: bottom, center;
        background-size: cover, cover;
        padding-left: 0;
        padding-right: 0;
      }

      .text-box {
        padding-top: 200px;
        min-height: 350px;
        max-height: 350px;
        transition: padding-top 500ms;

        h3 {
          font-size: 1.5em;
          line-height: 1.3;
        }

        .location {
          font-weight: 400;
          font-size: 1rem;
        }

        .date {
          font-weight: 400;
          font-size: 1.2rem;
        }

        .on-hover {
          display: none;
        }

        &:hover {
          padding-top: 60px;

          .on-hover {
            display: block;
          }
        }
      }
    }
  }

  &.card-mode {
    .event-card {
      width: 100%;
      border: 1px solid $cardBorderColor;
      border-radius: 5px;
      height: $cardPromoHeight + 200px;
      margin-bottom: $grid-gutter-width;
      background: $cardBgColor;
      position: relative;
      //@include float-shadow();

      .promo {
        height: $cardPromoHeight;
        border-radius: 5px 5px 0 0;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        position: relative;
      }

      .information {
        .date {
          background-color: $cardDateBgColor;
          color: $cardDateColor;
          padding: 4px 10px 1px 10px;
          font-size: 1.421em;

          * {
            font-family: $cardDateFontFamily;
          }

          .day {
            font-size: 2.5em;
            font-weight: bold;
            width: 65px;
            float: left;
            margin-top: -13px;
          }

          .day-of-week,
          .month {
            font-size: 1.1em;
            width: calc(100% - 120px);
            float: left;
            padding: 0;
            margin: -5px 0 -4px 0;
          }

          .day-of-week {
            font-weight: 300;
          }

          .month {
            font-weight: 300;
          }

          .type {
            width: 55px;
            height: 55px;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
            float: right;

            /*
            &.the {
              background-image: url('/bundles/oatevent/images/icon/THE.png');
            }

            &.dan {
              background-image: url('/bundles/oatevent/images/icon/DAN.png');
            }

            &.mov {
              background-image: url('/bundles/oatevent/images/icon/MOV.png');
            }

            &.mus {
              background-image: url('/bundles/oatevent/images/icon/MUS.png');
            }

            &.boo {
              background-image: url('/bundles/oatevent/images/icon/BOO.png');
            }

            &.tlk {
              background-image: url('/bundles/oatevent/images/icon/TLK.png');
            }

            &.exp {
              background-image: url('/bundles/oatevent/images/icon/EXP.png');
            }
             */
          }
        }

        h2,
        .info {
          padding: 0 10px;
        }

        h2 {
          font-family: $cardTitleFontFamily;
          color: $cardTitleColor;
          margin: 5px 0 10px 0;
          font-size: 1.5em;
          font-weight: bolder;

        }

        .info {
          letter-spacing: -1;
          position: absolute;
          bottom: 10px;

          .hour,
          .ticket,
          .location {
            font-family: $cardInfoFontFamily;
            color: $cardInfoColor;
            float: left;
          }

          .location {
            font-size: 1.1em;
            font-weight: lighter;
            width: 100%;
          }

          .hour {
            font-weight: bold;
            width: 90px;
          }

          .ticket {
            font-weight: lighter;
            width: calc(100% - 90px);
            font-size: 0.9em;
          }
        }
      }
    }
  }

  &.long-card {
    .event-card {
      .promo {
        .vip {
          font-family: "Roboto", sans-serif;
          position: absolute;
          color: $white;
          text-transform: uppercase;
          font-size: 1.25em;
          left: 20px;
          bottom: 20px;
          text-shadow: 0 0 2px $black, 0 0 5px $black;
        }
      }

      .information {
        .date {
          &.the {
            background-color: #ffd7d6;
          }

          &.dan {
            background-color: #f8daff;
          }

          &.mus {
            background-color: #d3dbff;
          }

          &.tlk {
            background-color: #fff8d2;
          }
        }

        .location {
          strong {
            color: $primary;
          }
        }
      }
    }
  }
}
