$galleryCardTextColor: $black;

.gallery {
  margin-top: 50px;
  margin-bottom: 50px;
  font-size: 0.853em;

  .card {
    .card-body {
      .description {
        font-size: 0.9em;
        color: $galleryCardTextColor;
      }
    }
  }
}

