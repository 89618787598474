$amazonListHover: $primary !default;
$amazonListActive: $white !default;

.amazon {
  &.multi-box {
  }

  &.single-box {
    display: none;

    &.active {
      display: block;
    }
  }

  .card-text {
    font-size: 1em;
  }
}

.amazon-list {
  margin-left: 0 !important;
  .list-group-item {
    cursor: pointer;
    a {
      &:hover {
        color: lighten($amazonListHover, 10%);
      }
    }
    &.active {
      font-weight: bold;
      a {
        color: $amazonListActive;
        &:hover {
          color: lighten($amazonListHover, 10%);
        }
      }
    }
  }
}