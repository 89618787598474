$eventHeaderHeight: 300px !default;
$eventHeaderColor: $primary !default;
$eventSloganFontFamily: Roboto, sans-serif !default;
$eventSloganColor: $primary !default;
$eventPreRoleColor: $gray-700 !default;
$eventRoleColor: $gray-800 !default;
$eventNameColor: $gray-900 !default;
$eventFontFamily: Roboto, sans-serif !default;
$eventColor: $primary !default;
$eventTicketColor: $warning !default;
$eventTicketPriceColor: $gray-900 !default;
$eventTicketButtonFontFamily: Roboto, sans-serif !default;
$eventTicketButtonColor: $danger !default;
$eventTicketButtonTextColor: $white !default;

.event {
  .long {
    display: inline-block;
    @media (max-width: map-get($grid-breakpoints, "xl")) {
      display: none;
    }
    @media (max-width: map-get($grid-breakpoints, "md")) {
      display: inline-block;
    }
  }

  .short {
    display: none;
    @media (max-width: map-get($grid-breakpoints, "xl")) {
      display: inline-block;
    }
    @media (max-width: map-get($grid-breakpoints, "md")) {
      display: none;
    }
  }

  .leaflet-popup-content-wrapper {
    .leaflet-popup-content {
      padding: 5px 10px;
      font-size: 1.25em;
      text-align: center;
    }
  }

  .header {
    margin-top: -137px;
    background-repeat: no-repeat;
    background-size: cover;
    height: $eventHeaderHeight;
    overflow: hidden;
    margin-bottom: 40px;
    position: relative;
    width: 100%;

    .gradient {
      margin-top: -410px;
      padding: ($eventHeaderHeight / 2) 0 0 0;
      opacity: 0.9;
      background: rgba(0, 0, 0, 0.25);
      height: $eventHeaderHeight;
    }

    * {
      font-family: "Oswald", sans-serif;
      color: $eventHeaderColor;
      line-height: 1.1;
      text-shadow: 0 0 10px #000000;
    }

    b, strong {
      font-size: 1.125em;
      @media (max-width: map-get($grid-breakpoints, "lg")) {
        font-size: 1em;
      }
      @media (max-width: map-get($grid-breakpoints, "md")) {
        font-size: 0.9em;
      }
    }

    h1 {
      margin: 0 0 90px 0;
      text-transform: uppercase;
      @media (max-width: map-get($grid-breakpoints, "lg")) {
        font-size: 3em;
      }
      @media (max-width: map-get($grid-breakpoints, "md")) {
        font-size: 2em;
        margin: 45px 0 45px 0;
      }
      @media (max-width: map-get($grid-breakpoints, "sm")) {
        font-size: 1.5em;
        margin: 90px 0 0 0;
      }
    }

    .dark {
      position: absolute;
      bottom: 0;
      padding: ($eventHeaderHeight / 8) 0;
      line-height: 0.8 !important;

      .location {
        font-size: 1.5em;
        font-weight: 500;
        text-transform: uppercase;
      }

      .dates {
        margin-top: 5px;
        vertical-align: top;
        text-transform: uppercase;

        .date {
          display: inline-block;
          font-weight: lighter;

          b, strong {
            font-weight: normal;
          }

          &:after {
            content: "|";
            display: inline;
            margin: 0 15px;
            font-size: 2.25em;
            font-weight: lighter;
          }

          &:last-child:after {
            content: " ";
          }
        }
      }
    }
  }

  .slogan {
    font-family: $eventSloganFontFamily;
    font-weight: 300;
    color: $eventSloganColor;
    text-transform: uppercase;
    margin-bottom: 20px;
  }

  .team-members {
    margin: 0;

    .team-member {
      margin: 0;
      padding: 10px !important;

      .team-image {
        img {
          border-radius: 50%;
        }
      }

      .team-desc {
        h3, h4 {
          text-transform: uppercase;
          letter-spacing: -1px;
        }
      }
    }
  }

  p, li {
    font-size: 1em;
  }

  .people, .people-h {
    margin-bottom: 20px;

    * {
      font-family: "Oswald", sans-serif;
    }

    &.with-picture {
      height: 50px;

      .picture {
        width: 50px;
        height: 50px;
        margin-right: 10px;
        display: inline-block;
        vertical-align: top;
      }

      .info {
        display: inline-block;
        vertical-align: middle;
        width: calc(100% - 70px);
      }

      &.vip {
        height: 75px;

        .picture {
          width: 75px;
          height: 75px;
          vertical-align: top;
          margin: 0 auto;
        }

        .info {
          display: inline-block;
          vertical-align: middle;
          width: calc(100% - 95px);
        }
      }
    }

    .info {
      .role {
        font-size: 1em;
        font-weight: lighter;
        color: $eventRoleColor;
        line-height: 1;
      }

      .name {
        text-transform: uppercase;
        font-weight: bolder;
        color: $eventNameColor;
        line-height: 1;

        &.v-centered {
          margin-top: 14px;
        }
      }
    }

    &.vip {
      .role {
        font-size: 1em;
        font-weight: lighter;
        color: $eventPreRoleColor;

        strong {
          color: $eventRoleColor;
        }
      }

      .name {
        font-size: 1.25em;
        font-weight: bolder;

        &.v-centered {
          margin-top: 24px;
        }
      }
    }
  }

  .people {
    font-size: 1.25em;
  }

  .desc {
    font-family: $eventFontFamily;
    font-size: 1.4em;
    color: $eventColor;
    text-align: justify;
  }

  .production {
    font-family: $eventFontFamily;
    font-size: 1.4em;
    color: $eventColor;
    text-align: justify;
    margin-top: 20px;
    padding-top: 20px;
    border-top: 1px solid $eventPreRoleColor;

    h3 {
      font-size: 1.8em;
    }
  }

  .tickets {
    text-align: center;

    img {
      margin: 10px auto;
      height: 85px;
    }

    table {
      width: 100%;

      th {
        text-align: center;
        font-size: 1.25em;
        padding: 10px;
      }

      td {
        padding: 10px;

        .ticket {
          text-align: left;
          color: $eventTicketColor;

          strong {
            float: right;
            color: $eventTicketPriceColor;
          }
        }
      }
    }

    .btn-ticket {
      font-family: $eventTicketButtonFontFamily;
      background-color: $eventTicketButtonColor;
      color: $eventTicketButtonTextColor;
      text-transform: uppercase;
      width: 100%;
    }
  }

}