@import "../../../../../node_modules/bootstrap/scss/functions";
@import "../../../../../assets/scss/poloVariables";
@import "../../../../../node_modules/bootstrap/scss/variables";
@import "../../../../oi-layout/polo/assets/scss/variables";
@import "../../../../oi-layout/polo/assets/scss/mixins";
@import "../../../../../node_modules/bootstrap/scss/variables";
@import "../../../../oi/ux/assets/scss/spacing";
@import "../../../../oi/ux/assets/scss/square";
@import "../../../../oi/ux/assets/scss/border";
@import "commons/ui";
@import "../../../../../node_modules/flag-icon-css/sass/flag-icon";

//body {
//  overflow-x: hidden;
//}

@include breakpoint-lg(max) {
  .mainMenu-open #header #mainMenu {
    width: 90vw;
    display: block;
    opacity: 1;
    overflow-y: scroll;
    background: $background-secondary-color;
    left: 0;
    position: absolute;
    top: 83px;
    padding: 20px;
    box-shadow: 5px 9px 5px #00000044;
  }
}

#header {
  z-index: 1001 !important;
  height: 100px;
  padding-top: 20px !important;
  margin-top: -20px !important;

  .header-inner {
    height: 100px !important;
    padding-top: 10px !important;
    margin-top: -10px !important;
  }

  #logo {
    top: 13px;
    @media (max-width: 500px) {
      top: 6px;
    }

    a > img {
      height: 55px;
      @media (max-width: 500px) {
        height: 35px;
      }
    }
  }


  #mainMenu {
    nav > ul {
      margin-top: 25px;
    }
  }

  .header-extras {
    padding-top: 21px;
  }

  &[data-transparent="true"] + .inspiro-slider,
  &[data-transparent="true"] + #slider,
  &[data-transparent="true"] + .fullscreen,
  &[data-transparent="true"] + .halfscreen,
  &[data-transparent="true"] + #google-map,
  &[data-transparent="true"] + #map {
    top: -95px;
    margin-bottom: -95px;
  }
}


#page-title {
  background-color: $background-primary-color !important;

  * {
    text-shadow: 0 0 25px gray, 0 0 1px black;

    small {
      font-weight: 400;
    }
  }

  h3 {
    margin: 20px auto;
  }

  p {
    font-size: 1.25em;
    letter-spacing: -1px;
    text-shadow: 0 0 5px black, 0 0 1px black;
  }

  &.institutional-page-header {
    @media(min-width: 768px) {
      height: 350px;
      padding: 158px 0 !important;
      h1 {
        font-size: 2.7em !important;
      }
    }

    .img-loaded {
      opacity: 0.5;
    }
  }

  .slide-captions {
    &.slide-captions-bottom {
      margin-top: 150px;
      margin-bottom: -150px;
      @media(max-width: 1024px) {
        margin-bottom: -70px;
      }
      @media(max-width: 768px) {
        margin-top: 50px;
        margin-bottom: -30px;
      }
    }

    > * {
      padding: 0 10px;
    }

    .overlay {
      background: rgba(0, 0, 0, 0.33);
      line-height: 1.25;
    }
  }

  .full-logo {
    img {
      width: 100vw;
      max-width: 100vw;
    }
  }
}

h1.page-title {
  color: $title-color;
}

p, table tr td, table tr th, ol li, ul li {
  font-size: 1.25rem;
  color: $text-color;
}

#footer {
  background-color: $footer-background-color;

  .widget, a:not(.btn) {
    color: $footer-text-color;
  }

  .copyright-content {
    background-color: $footer-text-color;
  }

  .crafted {
    color: $title-color;
  }
}

ol, ul {
  margin-left: 25px;

  li {
    line-height: 1.6;
  }
}

section {
  p {
    font-size: 1.2em;
  }
}

.producers-results-found {
  position: absolute;
  top: 20px;
  right: 20px;
  font-weight: bold;
  //width: 275px;
  z-index: 999;
  padding: 10px;
  //border: 2px solid rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  background-color: $background-primary-color;
}

.my-control {
  background: $background-primary-color;
  padding: 5px;
}


@keyframes pulse {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  60% {
    transform: scale(1.3);
    opacity: 0.4;
  }
  100% {
    transform: scale(1.4);
    opacity: 0;
  }
}

.leaflet-container a.leaflet-popup-close-button {
  color: $background-primary-color !important;
}

.leaflet-popup-content-wrapper {
  padding: 0 !important;

  .leaflet-popup-content {
    width: 250px;
    margin: 0 !important;

    .producer-cover-img {
      border-top-left-radius: 12px;
      border-top-right-radius: 12px;
      width: 251px;
      display: block;
    }

    .producer-data-div {
      padding: 9px;

      .btn {
        color: $background-secondary-color;
      }

      .producer-info-span {
        display: block;
      }

      .producer-info-activities-products-span {
        display: block;
        margin-top: 10px;

        .popup-miniature {
          margin-right: 3px;
          width: 20px;
          height: 20px;
        }
      }
    }

  }
}

.sidebar .widget:last-child {
  padding-bottom: 20px;
}

.text-colored, .text-colored a {
  color: $color-theme;
}

.infographic-icon--flexbox {
  display: flex;
  align-items: center;
  margin: auto;

  img {
    margin: 10px;
    padding: 10px;
    transition: margin 100ms;
  }

  &:hover {
    img {
      margin-left: 15px;
    }
  }
}

.placeholder-label {
  @media (max-width: 768px) {
    display: none;
  }
}

.producer-section {

  .producer-description {
    font-size: 1.5em;
  }
}

.producer-activities {
  p {
    font-size: 1.5em;
  }
}

.producer-product-types {
  p {
    font-size: 1.5em;
  }
}

.map-section {
  p {
    font-size: 1.5em;
  }
}

.producer-direct-sales {
  p {
    font-size: 1.5em;
  }
}

/* blog */

.breadcrumb {
  ul, ol {
    .breadcrumb-item a, li a {
      color: $breadcrumb-color;
    }
  }
}

.tags a, .widget a, .list li a {
  border-color: $tag-border-color;
  color: $text-color;
}

.post-item {
  .post-event-date-title {
    color: $breadcrumb-color;
  }

  .event-date-badge {
    background-color: $color-theme;
    padding: 6px;
    color: $text-color;
    border-radius: 5px;
  }

  .post-item-wrap {
    background-color: $background-primary-color;
  }
}

.single-post .post-item .post-item-description > h2, .single-post .post-item .post-item-description > h2 > a {
  font-size: 2.415rem;
  color: $text-color;
}

.share {
  font-size: 1.008em !important;
  height: 40px;

  > * {
    margin-right: 10px;
    float: left;
  }

  .emailButton {
    margin-top: 2px;
    margin-bottom: -4px;
  }

  .facebookButton {
    margin-top: 0;
    margin-bottom: 2px;
  }

  .twitterButton {
    margin-top: 8px;
    margin-bottom: -5px;
  }

  .linkedinButton {
    margin-top: 0;
    margin-bottom: 2px;
  }

  .pinterestButton {
    margin-top: 3px;
    margin-bottom: 6px;
  }

  .whatsappButton {
    margin-top: 2px;
    margin-bottom: 2px;
  }

  .email-share img, .whatsapp-share img {
    height: 21px !important;
    border: none;
    padding: 0;
    margin: 0;
    width: unset !important;
  }

}

section {
  ul {
    padding-left: 20px;

    li {
      margin-bottom: 10px;
    }
  }
}

.hr {
  .marker-cluster-small, .marker-cluster-medium, .marker-cluster-big {
    background: $background-contrast-color;

    div {
      background: $background-primary-color;
      color: $background-contrast-color;
    }
  }

  #producers-found {
    background: $background-primary-color;
    color: $text-color;
  }

  .pulsate::before {
    border: 4px double $background-secondary-color;
  }
}

.fs-22 {
  font-size: 1.479em;
}

.fs-2em {
  font-size: 2em;
  line-height: 2em;
}

/* Social links */

.social-links {
  padding-left: 0;

  .social-item-li {
    float: left;
    list-style: none;
    height: 32px;
    width: 32px;
    line-height: 2.273em;
    font-size: 1.5em;
    text-align: center;
    margin: 0 4px 4px 0;
    border-radius: 4px;
    border: 0;

    a {
      float: left;
      height: 32px;
      width: 32px;
      line-height: 2.273em;
      font-size: 1.076em;
      text-align: center;
      margin: 0 4px 4px 0;
      border-radius: 4px;
      border: 0;
      background: transparent;
      color: #fff;
      overflow: hidden;
    }

    &.social-facebook {
      background-color: #4267B2;
    }

    &.social-instagram {
      background-color: #E1306C;

      .fa-instagram {
        margin-top: 7px;
      }
    }

    &.social-googlemybusiness {
      background-color: #DB4437;

      svg {
        width: 65%;
        height: 65%;
      }
    }

    &.social-airbnb {
      background-color: #484848;
    }

    &.social-booking {
      background-color: #003580;
    }

    &.social-thefork {
      background-color: #f3cc0c;

      svg {
        width: 70%;
        height: 70%;
      }
    }

    &.social-tripadvisor {
      background-color: #00af87;
    }
  }
}

.producer-page-info-label {
  font-size: 1.3em;
}

.producer-page-info-value {
  &.site {
    font-size: 1.3em;
    text-decoration: underline;
  }
}

label {
  &.error {
    display: inline-block !important;
  }
}

.fs-1-3em {
  font-size: 1.3em;
  line-height: 1.3em;
}

.pv-0-i {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.p-cb {
  cursor: unset !important;
}

a.alert-link:not(.btn):hover, a.alert-link:not(.btn):focus, a.alert-link:not(.btn):active {
  text-decoration: none;
  outline: none;
  color: $text-color;
}

.alert {
  z-index: 9 !important;
}

.sponsor {
  div {
    height: 170px;

    div {
      white-space: nowrap;
      text-align: center;
      background-size: contain !important;
      background-repeat: no-repeat !important;
      background-position: center center !important;
    }
  }
}

.inspiro-slider {
  .slide-captions {
    @media (min-width: 768px) {
      padding: 0 50px;
    }
  }
}

@media (max-width: 576px) {
  #header #logo {
    a {
      margin-left: 15px;
    }

    position: absolute !important;
    width: 100%;
    text-align: unset !important;
    margin: 0 !important;
    float: none;
    height: 100px;
    right: 0;
    padding: 0 !important;
  }
}

.opposite-text {
  padding: 20px 5px !important;

  p, li {
    font-size: 0.9em;
  }

  .note {
    font-size: 0.75em;
  }
}

.cast-bio {
  .actor {
    p {
      font-size: 0.9em;
    }
  }
}

input[type="color"] {
  padding: 0;
  height: 10px;
}

@import "frontend/accordion";
@import "frontend/acs";
@import "frontend/amazon";
@import "frontend/blog";
@import "frontend/event";
@import "frontend/events";
@import "frontend/gallery";
@import "frontend/image_title";
@import "frontend/page";
@import "frontend/people";
@import "frontend/repertoire";
@import "frontend/timeline";
@import "frontend/typografy";

body, #page-content {
  background: $background-primary-color;
  color: $text-color;
}

.tickets .ticket {
  color: $title-color;
}

.card {
  border: 1px solid $cardBorderColor;
  background-color: $cardBgColor;

  .card-header {
    background-color: $cardBgColor;
    border-bottom: 1px solid $cardBorderColor;
  }

  .card-body {
    color: $text-color;

    a {
      color: $title-color;
    }
  }
}

.call-to-action.call-to-action-colored {
  background-color: $color-primary;
}

a, .event .slogan {
  color: $color-primary;
}

.testimonial.testimonial-box .testimonial-item {
  background-color: $background-secondary-color;
  border-color: $background-secondary-color;
}

.events.boxes {
  background: $background-primary-color;
}

aside * {
  color: $text-color;

  i, span {
    color: $white;
  }
}

.slide-captions-bottom h1 {
  font-size: 2.5em !important;
}

.title-special {
  line-height: 1.1;
}

.row {
  .container {
    margin: 0 !important;
    padding: 0 !important;
  }
}