div.timeline {
  &.github {
    padding-left: 24px !important;
    padding-right: 24px !important;
    padding-top: 12px !important;
    padding-bottom: 24px !important;

    &:before {
      border-left: none !important;
    }

    article {

      &.timeline-line {
        top: 48px;

        &:before {
          content: "";
          position: absolute;
          height: calc(100% - 24px);
          top: 68px;
          left: 28px;
          border-left: 2px solid #dedede;
        }
      }

      &.gutter-spacious {
        margin-right: -24px;
        margin-left: -24px;

        > [class=col-] {
          padding-right: 24px !important;
          padding-left: 24px !important;
        }
      }

      .timeline-single-details {
        padding-left: 52px;

        &.h75 {
          min-height: 75px;
        }

        &.h200 {
          min-height: 200px;
        }

        &.sticky {
          position: sticky;
          position: -webkit-sticky;
          width: 100%;
          top: 120px !important;
        }

        svg {
          background-color: $background-primary-color;
        }

        .left-0 {
          left: 0 !important;
        }

        h2 {
          font-size: 20px !important;
          line-height: 24px !important;
          font-weight: 600 !important;
          margin-bottom: 0;

          @media (min-width: 768px) {
            font-size: 24px !important;
            line-height: 28px !important;
            font-weight: 800 !important;
          }

          small {
            font-size: 16px !important;
            line-height: 20px !important;
            font-weight: 400 !important;

            @media (min-width: 768px) {
              font-size: 20px !important;
              line-height: 24px !important;
              font-weight: 600 !important;
            }
          }
        }

        p {
          margin-top: 0;
        }
      }

      .timeline-single-content {
        margin-left: 48px;
        @media (min-width: 768px) {
          margin-left: 0;
        }

        p {
          margin-bottom: 4px;
          font-size: 1rem;
        }
      }
    }
  }
}
