.oac-title {
  margin-top: 10px;
}

.oac-content {
  margin-left: 30px;
}

section {
  &.no-padding {
    .p-100 {
      @media (max-width: map-get($grid-breakpoints, "md")) {
        padding: 45px;
      }
    }
  }
}